import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 185.000000 166.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,166.000000) scale(0.100000,-0.100000)">
          <path d="M750 1443 c-25 -15 -58 -39 -74 -54 -21 -19 -45 -30 -80 -34 -117
-16 -208 -81 -260 -186 -33 -66 -36 -79 -36 -158 0 -47 5 -105 10 -128 8 -32
6 -52 -4 -80 -57 -147 -47 -298 25 -389 47 -60 126 -110 208 -133 63 -17 80
-28 128 -75 31 -31 59 -53 64 -51 4 3 11 0 14 -5 11 -18 95 -46 95 -32 0 5
-15 13 -32 17 -29 6 -31 8 -13 15 11 5 14 9 6 9 -7 1 -31 15 -52 32 -21 17
-43 28 -47 25 -5 -3 -9 5 -8 17 1 19 8 22 44 25 23 2 42 7 42 11 0 18 -26 29
-60 27 -65 -5 -93 10 -123 64 -33 60 -50 110 -36 110 5 0 37 -22 72 -49 69
-54 175 -116 183 -108 3 3 -4 8 -15 12 -12 3 -21 11 -21 17 0 6 7 7 18 3 38
-16 57 -16 29 0 -15 9 -36 15 -45 14 -28 -2 -38 1 -25 9 9 5 -11 25 -60 60
-40 28 -98 78 -131 111 l-58 59 -11 86 c-12 103 2 286 26 332 20 37 143 127
249 181 l83 43 -45 -1 c-45 -1 -170 -52 -170 -70 0 -5 -5 -9 -12 -9 -14 0
-112 -73 -154 -115 -16 -16 -47 -51 -68 -77 -21 -27 -42 -48 -47 -48 -5 0 -9
39 -9 86 0 71 4 94 26 139 29 63 96 126 153 145 48 15 61 8 41 -23 -25 -38
-70 -130 -66 -134 13 -13 56 13 67 40 7 18 30 58 52 91 l40 59 81 -7 c76 -5
80 -5 61 10 -19 16 -18 16 15 10 23 -4 30 -3 20 3 -8 5 -36 13 -62 16 -49 7
-64 26 -30 40 16 7 15 8 -3 16 -17 8 -17 9 5 5 14 -3 36 2 50 10 l25 15 -25
-7 c-22 -6 -23 -5 -11 10 23 27 8 27 -39 -1z m-303 -661 c2 -163 25 -268 83
-387 16 -33 25 -64 21 -68 -23 -23 -172 87 -205 152 -25 49 -37 132 -27 194
16 93 28 100 62 32 12 -25 26 -45 31 -45 15 0 8 68 -12 115 -21 49 -26 85 -12
85 5 0 16 16 25 35 9 19 20 35 24 35 5 0 9 -66 10 -148z m297 -607 c11 -8 15
-15 10 -15 -6 0 -19 7 -30 15 -10 8 -14 14 -9 14 6 0 19 -6 29 -14z"/>
<path d="M818 1463 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M848 1303 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M828 1263 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M910 1049 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M828 938 c-76 -115 -108 -167 -108 -177 0 -7 29 -11 83 -10 70 1 75
3 35 8 -55 8 -60 11 -48 31 6 10 10 11 10 3 0 -7 7 -10 15 -7 14 6 16 37 2 50
-19 20 -3 74 36 118 16 18 22 36 13 36 -2 0 -19 -24 -38 -52z m-52 -140 c-9
-12 -16 -17 -16 -10 0 18 24 54 28 42 2 -5 -4 -20 -12 -32z"/>
<path d="M845 920 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M826 891 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M855 870 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M830 300 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3 -15
1 -15 -4z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
